<template>
    <div>
        <h2><router-link :to="{ name: 'stock' }">สต็อก</router-link> > แยกตามสินค้าลูก</h2>
        <v-card class="mt-4 px-6 pt-6">
            <v-row>
              <v-col cols="12" md="4">
                <delay-autocomplete
                  outlined
                  hide-selected               
                  deletable-chips
                  multiple
                  chips 
                  cache-items
                  v-model="datatable.warehouse_id"             
                  label="คลัง"
                  placeholder="พิมพ์เพื่อค้นหาคลัง"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/inventory/warehouseList'"
                  dense
                  :lazySearch="true"
                  return-object
                  ref="warehouseAutocomplete"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                ></delay-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  v-model="datatable.search"
                  placeholder="ค้นหา ..."
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-checkbox
                  label="ซ่อนที่สต็อคเป็น 0"
                  v-model="datatable.hide_zero"
                  class="mt-0"
                ></v-checkbox>
              </v-col>                        
            </v-row>            
        </v-card>
        <v-card class="mt-4">
          <v-row class="align-center">
            <v-col cols="1"
            >
              <v-btn
              icon
              @click="$router.go(-1)"
              ><v-icon>{{ mdiChevronLeft }}</v-icon></v-btn>
            </v-col>
            <template v-if="!!product">
              <v-col cols="1">
                <product-image :image="product.images[0] ? product.images[0].URI : null" />
              </v-col>
              <v-col>
                {{ product.name }}
              </v-col>
              <v-col>
                {{ product.sku }}
              </v-col>
              <v-col>
                {{ product.brand ? product.brand.name : '-' }}
              </v-col>
              <v-col cols="1">
                {{ getQuantitySum }}
              </v-col>
              <v-col cols="1">
                {{ getReservedSum }}
              </v-col>
              <v-col cols="1">
                {{ getQuantitySum - getReservedSum }}
              </v-col>
            </template>            
          </v-row>
          <delay-datatable
            :headers="datatable.headers"
            :options.sync="datatable.options"  
            :url="'/inventory/' + $route.params.product_id + '/listbyvariation'"            
            :queryParams="getDatatableQueryParams"            
            @loadState="loadDatatableState"            
            ref="mainDatatable"
          >
          
          <template          
            v-slot:item.images="{ item }"
          >   
            <product-image :image="item.images[0] ? item.images[0].URI : null" />                           
          </template>
          
          <template          
            v-slot:item.brand_name="{ item }"
          >   
            {{ !!item.brand_name ? item.brand_name : '-' }}
          </template>

          <!--
          <template          
            v-slot:item.sum_quantity="{ item }"
          >   
            {{ reduceInventory(item.inventory) }}
          </template>

          <template          
            v-slot:item.sum_reserved="{ item }"
          >   
            {{ reduceReserved(item.inventory) }}
          </template>

          <template          
            v-slot:item.sum_available="{ item }"
          >   
            {{ sumAvailable(item.inventory) }}
          </template>
          -->
          
          <template          
            v-slot:item.actions="{ item }"
          >   
            <v-btn
              icon
              @click="$router.push({name: 'stock_view', params: { product_id: item.id }, query: { warehouses: converters.jsonToBase64(datatable.warehouse_id) }})"
            ><v-icon>{{ mdiEyeOutline }}</v-icon></v-btn>            
          </template>
          </delay-datatable>
        </v-card>
        <v-dialog
          v-model="editDialog.isOpen"
          width="400"
          persistent
        >
          <v-card class="py-8 px-6">
            <v-row no-gutters>
              <v-col>
                <label for="" class="text-h6">ปรับสต็อค &gt; {{ editDialog.warehouse }}</label>   
              </v-col>
            </v-row>            
            <v-row no-gutters>
              <v-col>
                <label for="" class="text-caption">อัพเดธจำนวนสินค้า</label>         
              </v-col>
            </v-row>            
            <v-row>
              <v-col cols="4">
                <product-image :image="!!product && !!product.images && !!product.images[0] ? product.images[0].URI : null" />
              </v-col>
              <v-col cols="8" class="d-flex align-center">
                <div class="d-flex flex-column">
                  <label for="">{{ product ? product.name : '' }}</label>
                  <label class="mt-2 text-subtitle-2">{{ product ? product.sku : '' }}</label>
                </div>                
              </v-col>
            </v-row>
            <v-row>
              <v-col>จำนวนปัจจุบัน</v-col><v-col class="text-right">{{ editDialog.currentQuantity }}</v-col>
            </v-row>
            <v-row>
              <v-col>จำนวนติดจอง</v-col><v-col class="text-right">{{ editDialog.currentReserved }}</v-col>
            </v-row>
            <v-row>
              <v-text-field
                outlined
                v-model="editDialog.newQuantity"
                placeholder="จำนวนสินค้าใหม่"
                dense
              ></v-text-field>
            </v-row>
            <v-card-actions class="justify-center">
              <v-btn
                text                 
                @click="editDialog.isOpen = false"
                :disabled="editDialog.isSubmitting"
              >ยกเลิก</v-btn>
              <v-btn
                color="primary"
                @click="updateStock()"               
                :loading="editDialog.isSubmitting"
              >แก้ไข</v-btn>
            </v-card-actions>            
          </v-card>
        </v-dialog>
    </div>
</template>
<script>
import ProductImage from '@/components/ProductImage.vue'
import { asyncGet, asyncUpdate } from '@/helpers/asyncAxios'
import { base64ToJson, jsonToBase64 } from '@/helpers/converter.js'
import {
  mdiChevronLeft,
  mdiExportVariant,
  mdiEyeOutline,
  mdiFormatListText,
  mdiHistory,
  mdiImport,
  mdiPlaylistEdit,
} from '@mdi/js'
export default {
  data() {
    return {
      product: null,
      editDialog: {
        isOpen: false,
        isSubmitting: false,
        warehouse: '',
        currentQuantity: 0,
        currentReserved: 0,
        newQuantity: null,
        inventoryId: null,
      },
      datatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        selectedItems: [],
        brand_id: null,
        warehouse_id: null,
        search: null,
        hide_zero: false,
        headers: [
          {
            text: 'รูป',
            value: 'images',
            sortable: false,
          },
          {
            text: 'ชื่อสินค้า',
            value: 'name',
          },
          {
            text: 'sku',
            value: 'sku',
          },
          {
            text: 'บาร์โคด',
            value: 'barCode',
          },
          {
            text: 'จำนวน',
            value: 'sum_quantity',
          },
          {
            text: 'ติดจอง',
            value: 'sum_reserved',
          },
          {
            text: 'ขายได้',
            value: 'sum_available',
          },
          {
            text: '#',
            value: 'actions',
            sortable: false,
            align: 'center',
          },
        ],
      },
      mdiEyeOutline,
      mdiExportVariant,
      mdiImport,
      mdiFormatListText,
      mdiChevronLeft,
      mdiHistory,
      mdiPlaylistEdit,
      converters: {
        jsonToBase64,
      },
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        brand_id: this.datatable.brand_id,
        warehouse_id: this.datatable.warehouse_id,
        hide_zero: this.datatable.hide_zero,
        search: this.datatable.search,
      }
    },
    getQuantitySum() {
      if (!this.product) return 0

      return this.product.variations
        .flatMap(variation => variation.inventory)
        .map(inventory => inventory.quantity)
        .reduce((total, currentQuantity) => {
          return total + currentQuantity
        })
    },
    getReservedSum() {
      if (!this.product) return 0

      return this.product.variations
        .flatMap(variation => variation.inventory)
        .map(inventory => inventory.reserved)
        .reduce((total, currentReserved) => {
          return total + currentReserved
        })
    },
  },
  async created() {
    if (this.$route.query.warehouses) {
      const warehouse_id = base64ToJson(this.$route.query.warehouses)

      this.datatable.warehouse_id = warehouse_id
    }

    const product_id = this.$route.params.product_id

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/inventory/' + product_id + '/getbyproduct')

      this.product = response

      this.$refs.mainDatatable.reload()
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }
    },
    isArray(data) {
      return Array.isArray(data)
    },
    reduceInventory(inventory) {
      if (!!!inventory || inventory.length == 0) return 0

      return inventory
        .map(data => data.quantity)
        .reduce((total, currentQuantity) => {
          return total + currentQuantity
        })
    },
    reduceReserved(inventory) {
      if (!!!inventory || inventory.length == 0) return 0

      return inventory
        .map(data => data.reserved)
        .reduce((total, currentReserved) => {
          return total + currentReserved
        })
    },
    sumAvailable(inventory) {
      if (!!!inventory || inventory.length == 0) return 0

      const currentQuantity = this.reduceInventory(inventory)
      const currentReserved = this.reduceReserved(inventory)

      return currentQuantity - currentReserved
    },
    async updateStock() {
      this.editDialog.isSubmitting = true
      const diff = this.editDialog.newQuantity - this.editDialog.currentQuantity

      try {
        await asyncUpdate('/inventory/' + this.editDialog.inventoryId, {
          diff: diff,
        })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.$refs.mainDatatable.reload()
      this.editDialog.isSubmitting = false
      this.editDialog.isOpen = false
    },
  },
  components: {
    ProductImage,
  },
}
</script>